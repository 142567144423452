<template>
  <div class="container-fluid main-container">
    <div class="row">
      <div class="col-3">
        <div class="card mb-2">
          <div class="card-body">
            <div class="mb-3">
              <select class="form-select" v-model="currentMap">
                <option value="0">选择地图</option>
                <option value="695">迷津</option>
                <option value="696">萨维奈岛</option>
                <option value="697">加雷马</option>
                <option value="698">叹息海</option>
                <option value="699">天外天垓</option>
                <option value="700">厄尔庇斯</option>
              </select>
            </div>
            <div>
              <button class="btn btn-outline-primary d-block" style="width: 100%" type="button" @click="getCurrent">
                查看风脉泉
              </button>
            </div>
          </div>
        </div>
        <div class="card mb-2">
          <div class="card-header">风脉泉列表</div>
          <div class="card-body test-center text-muted" v-if="currentList == null">
            无结果
          </div>
          <ul class="list-group list-group-flush">
            <li class="list-group-item" v-for="(current, index) in currentList" :key="index">
              <b>{{ index + 1 }}.</b> X: {{ current.x }}, Y: {{ current.y }}, Z:
              {{ current.z }}
              <button class="btn btn-outline-secondary current-btn" type="button"
                @click="createHistoryEntry(currentMap, index)">
                {{ getHistoryStatus(currentMap, index) ? "未完成" : "完成" }}
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-9 main-col">
        <div class="text-center" v-if="currentMap == 0 || currentList == null">
          <p class="text-muted">请选择地图...</p>
        </div>
        <div class="map-container" v-else-if="currentList != null">
          <div class="map">
            <img :src="mapURL" class="img-fluid rounded map-image" />
            <div class="map-marker-div" v-for="(current, index) in currentList" :key="index" :style="
              'left: calc(' +
              current.map_left +
              '% - 15px); top: calc(' +
              current.map_top +
              '% - 15px)'
            " v-show="getHistoryStatus(currentMap, index) == false">
              <img src="@/assets/current.png" class="rounded map-marker me-1" :title="'风脉泉 ' + (index + 1)" />
              <span class="badge bg-secondary">{{ index + 1 }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Current",
  data() {
    return {
      currentMap: 0,
      currentList: null,
      currentHistory: {},
    };
  },
  computed: {
    mapURL() {
      switch (this.currentMap) {
        case "695":
          return require("@/assets/map/k5f1.png");
        case "696":
          return require("@/assets/map/m5f1.png");
        case "697":
          return require("@/assets/map/m5f2.png");
        case "698":
          return require("@/assets/map/u5f1.png");
        case "699":
          return require("@/assets/map/u5f2.png");
        case "700":
          return require("@/assets/map/n5f1.png");
        default:
          return require("@/assets/map/default.png");
      }
    },
  },
  watch: {
    currentMap() {
      this.currentList = null;
    },
  },
  methods: {
    getCurrent() {
      if (this.currentMap == '0') {
        return;
      }

      var self = this;
      this.axios
        .post(this.apiBaseURL + "current.php?method=searchMapCurrent", {
          mapID: this.currentMap,
        })
        .then(function (response) {
          if (response.data.success) {
            self.currentList = response.data.data;
          } else {
            alert("加载失败: " + response.data.error);
          }
        })
        .catch(function (error) {
          alert("加载失败: " + error);
        });
    },
    createHistoryEntry(mapID, currentID) {
      if (this.currentHistory[mapID] == null) {
        this.currentHistory[mapID] = {};
      }

      var status;
      try {
        status = this.currentHistory[mapID][currentID];
      } catch (error) {
        status = undefined;
      }

      if (status === undefined) {
        this.currentHistory[mapID][currentID] = true;
      } else {
        if (this.currentHistory[mapID][currentID]) {
          this.currentHistory[mapID][currentID] = false;
        } else {
          this.currentHistory[mapID][currentID] = true;
        }
      }

      if (this.canUseLocalStorage) {
        var storage = window.localStorage;

        storage.setItem("currentHistory", JSON.stringify(this.currentHistory));
      }
    },
    getHistoryStatus(mapID, currentID) {
      if (this.currentHistory[mapID] == null) {
        return false;
      }

      var status;
      try {
        status = this.currentHistory[mapID][currentID];
      } catch (error) {
        status = undefined;
      }

      if (status === undefined) {
        return false;
      } else {
        return this.currentHistory[mapID][currentID];
      }
    },
  },
  mounted() {
    if (this.$root.storageAvailable("localStorage")) {
      this.canUseLocalStorage = true;

      var storage = window.localStorage;

      if (storage.getItem("currentHistory") != null) {
        this.currentHistory = JSON.parse(storage.getItem("currentHistory"));
      }
    } else {
      console.log("localStorage not available.");
    }
  },
};
</script>

<style scope>
.card-content {
  height: 100%;
}

.main-col {
  min-height: 550px;
  max-height: calc(100vh - 140px);
}

.map-container {
  display: flex;
  align-content: center;
  justify-content: center;
}

.map {
  width: fit-content;
  position: relative;
}

.map-image {
  max-height: 85vh;
}

.map-marker-div {
  position: absolute;
  z-index: 100;
}

.map-marker {
  height: 20px;
  width: 20px;
}

.current-btn {
  font-size: 0.75em;
  font-weight: 700;
  line-height: 0.8;
}
</style>
